import React, { useEffect, useState, useReducer } from 'react'
import XmlBlock from '@components/jsonRenderer/XmlBlock'
import ParsingOptionsContext from '@helpers/contexts/ParsingOptionsContext'
import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'
import CodeEditor from '@uiw/react-textarea-code-editor'
import { editorStyle } from '@helpers/config'
import { calcGrid } from '@helpers/utils.js'

import 'react-reflex/styles.css'

const XmlViewer = (props) => {

  const [ rawXml, setRawXml ] = useState('');
  const [ parsedXmlWithGrid, setParsedXmlWithGrid ] = useState({});
  const [ validationError, setValidationError ] = useState('');
  const [ processing, setProcessing ] = useState(false);

  const reparse = () => {
    setProcessing(true);
    const parser = require('fast-xml-parser');
    const parsingProcess = Promise.resolve(rawXml);
    parsingProcess.then(res => {
      const validation = parser.validate(res);
      if (typeof validation === 'object') {
        setValidationError('XML did not pass validation. Object with validation report is displayed below');
        return calcGrid(validation, props.options);
      }
      else {
        setValidationError();
        const parsedXml = parser.parse(res, props.options);
        return calcGrid(parsedXml, props.options);
      }
    })
    .then(res => {
      console.log(res);
      setParsedXmlWithGrid(res);
    })
    .catch(err => setValidationError('XML fetch failed with error: '+err))
    .finally(() => {
      setProcessing(false);
    })
  };

  return <React.Fragment>
  <ReflexContainer orientation="vertical">

    <ReflexElement className="left-pane p-2" minSize="50">
      <h3>XML content</h3>
      {
        validationError !== '' && <div className="text-danger">{validationError}</div>
      }
      <div className="grid-wrapper">
        <ParsingOptionsContext.Provider value={props.options}>
          <XmlBlock content={parsedXmlWithGrid} level={1} attr={null} path="" />
        </ParsingOptionsContext.Provider>
      </div>
      <div className="settings-hook-placeholder"></div>
    </ReflexElement>

    <ReflexSplitter/>

    <ReflexElement className="right-pane" minSize="50">
      <div className="pane-content">
        <div>
          <CodeEditor
            value={rawXml}
            language="xml"
            placeholder="Put XML here"
            onChange={e => setRawXml(e.target.value)}
            padding={15}
            style={editorStyle}
          />
        </div>
      </div>

      <div className="sticky-bottom p-1 text-end">
        {
          processing
          ? <button className="btn btn-success" disabled>Parsing...</button>
          : <button className="btn btn-success" onClick={() => reparse()}>Parse</button>
        }
      </div>
    </ReflexElement>

  </ReflexContainer>
  </React.Fragment>;
}

export default XmlViewer;
