import React, { useContext } from 'react'
import AttributesBlock from '@components/jsonRenderer/AttributesBlock.js'
import ParsingOptionsContext from '@helpers/contexts/ParsingOptionsContext'
import CopyButton from './CopyButton'
import { app } from '@helpers/config'

const XmlBlock = (props) => {

  const parsingOptions = useContext(ParsingOptionsContext);

  if (props.level > app.maxDepth) {
    return window.alert(`Max depth of ${app.maxDepth} reached, render is stopped`);
  }

  const attrNodeName = parsingOptions.attrNodeName;
  const gridHeightNodeName = parsingOptions.gridHeightNodeName;
  const content = props.content;
  const blockType = typeof content;
  const style = { gridColumn: `${props.level}` };
  const attr = props.attr;

  if (blockType === 'object') {
    const keys = Object.keys(content);

    return <>
      {
        keys.map(nodeName => {

          if (nodeName === gridHeightNodeName) {
            return false;
          }

          /*
          If we should not separate arrays indexes in column:
          If node content's childs' keys are numeric values (except grid parameter), that means it is array.
          In that case we should check if indexes should be separated, and if not -
          we skip block render and tell next blocks (indexes) to add parent name,
          like books[0], books[1]
          */
          if (parsingOptions.separateIndexes !== true && Array.isArray(content[nodeName])) {
            const childContent = content[nodeName];
            if (Array.isArray(childContent)) {
              return <>
                {
                  childContent.map((elem, index) => {
                    const preparedContent = { [nodeName]: elem };
                    return <XmlBlock content={preparedContent} attr={attr} level={props.level} path={props.path} displayName={`${nodeName}[${index}]`} />
                  })
                }
              </>
            }
          }


          /****************/


          const path = props.displayName
          ? props.path + '/' + props.displayName
          : props.path + '/' + nodeName;

          // if attrNodeName is not set, render attributes as separate cells
          if (attrNodeName === false) {

            const prefix = parsingOptions.attributeNamePrefix;
            const gridHeight = content[nodeName][gridHeightNodeName] ?? 1;
            const nextAttr = content[nodeName][attrNodeName] ?? null;
            const displayNodeName = props.displayName ?? nodeName;

            return <React.Fragment key={`grid-block-${path}`}>
              <div className={"xml-block " + (nodeName.startsWith(prefix) ? "attr-cell" : "tag-cell")} style={{...style, gridRowEnd: `span ${gridHeight}`}} data-level={props.level}>
                <div className={gridHeight > 1 ? "sticky-top" : ""}>
                  {displayNodeName}
                </div>
                <CopyButton path={path} />
              </div>
              <XmlBlock content={content[nodeName]} attr={nextAttr} level={props.level + 1} path={path} />
            </React.Fragment>
          }
          // if current block is not an attribute
          else if (nodeName !== attrNodeName) {

            const nextAttr = content[nodeName][attrNodeName] ?? null;
            const gridHeight = content[nodeName][gridHeightNodeName] ?? 1;
            const displayNodeName = props.displayName ?? nodeName;

            return <React.Fragment key={`grid-block-${path}`}>
              <div className="xml-block tag-cell" style={{...style, gridRowEnd: `span ${gridHeight}`}} data-level={props.level}>
                <div className={gridHeight > 1 ? "sticky-top" : ""}>
                  { displayNodeName }
                  <AttributesBlock list={nextAttr} />
                </div>
                <CopyButton path={path} />
              </div>
              <XmlBlock content={content[nodeName]} attr={nextAttr} level={props.level + 1} path={path} />
            </React.Fragment>
          }
        })
      }
    </>;
  }
  else {
    return <div className="xml-block value-cell" style={style} data-level={props.level}>
      {content}
      <AttributesBlock list={attr} />
    </div>;
  }

}

export default XmlBlock;
