import { copyToClipboard } from '@helpers/utils'
import { useAlert } from "react-alert"

import "bootstrap-icons/font/bootstrap-icons.css"

const CopyButton = (props) => {

  const alert = useAlert();

  const copyToClipboard = (text, showAlert = false) => {
    window.navigator.clipboard.writeText(text).then(
      () => {
        if (showAlert) {
          alert.show(`Copied: ${text}`);
        }
      },
      (err) => {
        window.alert('Could not copy text: ', err);
      }
    );
  }

  return <>
    <i className="bi-clipboard copy-path-btn" onClick={() => copyToClipboard(props.path, true)}></i>
  </>;
}

export default CopyButton;
