const AttributesBlock = (props) => {

  if (props.list === null) return false;

  const list = props.list;

  return Object.keys(list).map(key =>
    <div className="text-success">{key} : {list[key]}
  </div>)
}

export default AttributesBlock;
