export const app = {
  defaultGridHeightNodeName: '#gridHeight',
  maxDepth: 1000
}

export const editorStyle = {
  fontSize: 14,
  backgroundColor: "#f5f5f5",
  fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
}

export const defaultParserOptions = {
  attributeNamePrefix : "@",
  attrNodeName: false, //default is 'false'
  textNodeName : "#text",
  ignoreAttributes : false,
  ignoreNameSpace : true,
  allowBooleanAttributes : false,
  parseNodeValue : true,
  parseAttributeValue : false,
  trimValues: true,
  cdataTagName: false, //default is 'false'
  cdataPositionChar: "\\c",
  parseTrueNumberOnly: false,
  numParseOptions: {
    hex: true,
    leadingZeros: true
  },
  gridHeightNodeName: app.defaultGridHeightNodeName,
  separateIndexes: false
};
