import { v4 as uuidv4 } from 'uuid';

const inputBlocksReducer = (state, action) => {

  const add = (blocks) => {
    let newBlocks = [ ...blocks ];
    newBlocks.push({
      name: '',
      id: uuidv4(),
      content: ''
    });
    return newBlocks;
  }

  const remove = (blocks, id) => {
    return ([ ...blocks ].filter(block => block.id !== id));
  }

  const rename = (blocks, index, name) => {
    let newBlocks = [ ...blocks ];
    newBlocks[index].name = name;
    return newBlocks;
  }

  const edit = (blocks, index, content ) => {
    let newBlocks = [ ...blocks ];
    newBlocks[index].content = content;
    return newBlocks;
  }

  let newState;

  switch (action.type) {
    case 'add':
      newState = add(state);
      break;
    case 'remove':
      newState = remove(state, action.block_id);
      break;
    case 'rename':
      newState = rename(state, action.block_index, action.name);
      break;
    case 'edit':
      newState = edit(state, action.block_index, action.content);
      break;
    default:
      newState = [];
  }

  return newState;
}

export default inputBlocksReducer;
