import React, { useEffect, useState, useReducer } from 'react'
import XmlBlock from '@components/jsonRenderer/XmlBlock'
import ParsingOptionsContext from '@helpers/contexts/ParsingOptionsContext'
import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'
import CodeEditor from '@uiw/react-textarea-code-editor'
import inputBlocksReducer from '@helpers/reducers/inputBlocksReducer'
import { editorStyle } from '@helpers/config'
import { calcGrid } from '@helpers/utils.js'

import 'react-reflex/styles.css'

const XmlMerger = (props) => {

  const [ rawXml, setRawXml ] = useState('');
  const [ parsedXml, setParsedXml ] = useState({});
  const [ parsedXmlWithGrid, setParsedXmlWithGrid ] = useState({});
  const [ validationError, setValidationError ] = useState('');
  const [ inputBlocks, dispatchInputBlocks ] = useReducer(inputBlocksReducer, []);
  const [ collapsedBlocks, setCollapsedBlocks ] = useState([]);

  const parser = require('fast-xml-parser');
  const reparse = () => {
    const url = '/api/';
    const data = new FormData();
    data.append('payload', JSON.stringify(inputBlocks));
    fetch(url, { method: 'POST', body: data })
    .then(res => res.json())
    .then(res => {
      setParsedXml(res);
    })
    .catch(err => setValidationError(`Fetching failed with error: ${err}`))
  };

  useEffect(() => {
    const jsonWithGrid = calcGrid(parsedXml, props.options);
    setParsedXmlWithGrid(jsonWithGrid);
  }, [ parsedXml ]);

  const toggleCollapse = (id) => {
    let newState;
    if (collapsedBlocks.includes(id)) {
      newState = [ ...collapsedBlocks ].filter(elem => elem !== id);
    }
    else {
      newState = [ ...collapsedBlocks ];
      newState.push(id);
    }
    setCollapsedBlocks(newState);
  }

  return <React.Fragment>
  <ReflexContainer orientation="vertical">

    <ReflexElement className="left-pane p-2" minSize="50">
      <h3>XML content</h3>
      {
        validationError !== '' && <div className="text-danger">{validationError}</div>
      }
      <div className="grid-wrapper">
        <ParsingOptionsContext.Provider value={props.options}>
          <XmlBlock content={parsedXmlWithGrid} level={1} attr={null} />
        </ParsingOptionsContext.Provider>
      </div>
      <div className="settings-hook-placeholder"></div>
    </ReflexElement>

    <ReflexSplitter/>

    <ReflexElement className="right-pane" minSize="50">
      <div className="pane-content">
        {
          inputBlocks.map((block, index) => <div key={block.id}>
            <div className="p-1 d-flex align-items-center justify-content-between">
              <div>
                <input type="text" className="form-control-plaintext p-1" onChange={e => dispatchInputBlocks({ type: 'rename', block_index: index, name: e.target.value })} placeholder="Title or filename" />
              </div>
              <div>
                <button className="btn btn-sm btn-secondary" alt="collapse block" onClick={() => toggleCollapse(block.id)}>{collapsedBlocks.includes(block.id) ? '-' : '+'}</button>&nbsp;
                <button className="btn btn-sm btn-danger" alt="delete block" onClick={() => dispatchInputBlocks({ type: 'remove', block_id: block.id })}><b>X</b></button>
              </div>
            </div>
            {
              collapsedBlocks.includes(block.id) &&
              <div>
                <CodeEditor
                  value={block.content}
                  language="xml"
                  placeholder="Put XML here"
                  onChange={e => dispatchInputBlocks({ type: 'edit', block_index: index, content: e.target.value })}
                  padding={15}
                  style={editorStyle}
                />
              </div>
            }
          </div>)
        }

        <div className="sticky-bottom p-1 text-end">
          <button className="btn btn-secondary" onClick={() => dispatchInputBlocks({ type: 'add' })}>Add block</button>&nbsp;
          <button className="btn btn-success" onClick={() => reparse()}>Merge</button>
        </div>
      </div>
    </ReflexElement>

  </ReflexContainer>
  </React.Fragment>;
}

export default XmlMerger;
