import { useState, useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import XmlMerger from '@components/main/XmlMerger'
import XmlViewer from '@components/main/XmlViewer'
import Settings from '@components/main/Settings'

import { defaultParserOptions } from '@helpers/config'

import '@styles/App.css'
import 'react-tabs/style/react-tabs.css'
import '@styles/react-tabs-override.css'

const App = () => {

  const [ parserOptions, setParserOptions ] = useState({});

  useEffect(() => {
    setParserOptions(defaultParserOptions);
  }, []);

  return <>
  <Tabs>
    <TabList>
      <Tab>Merger</Tab>
      <Tab>Viewer</Tab>
    </TabList>

    <TabPanel>
      <XmlMerger options = {parserOptions} />
    </TabPanel>
    <TabPanel>
      <XmlViewer options = {parserOptions} />
    </TabPanel>
  </Tabs>

  <Settings
    options = {parserOptions}
    setParserOptions = {setParserOptions}
    />

  <div className="credits">
    XML Workshop v{process.env.REACT_APP_VERSION}&nbsp;&nbsp;
  </div>
  </>;
}

export default App;
