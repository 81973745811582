import React from 'react';
import ReactDOM from 'react-dom';
import '@styles/index.css';
import App from '@components/App';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from '@helpers/alerts/AlertTemplate';

import 'bootstrap/dist/css/bootstrap.min.css';

const alertOptions = {
  position: 'middle',
  timeout: 1200,
  offset: '400px',
  transition: 'fade'
}

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} { ...alertOptions }>
      <App />
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
