import { defaultParserOptions } from './config'

export const sumArr = (arr) => {
   return arr.reduce((a, b) => a + b, 0);
}

/* calculate abstract grid-row height of each block to include it into blocks' css while rendering */
/*
input
root
  ch1
  ch2
    ch2-1
    ch2-2
  ch3

output
root
  ch1
    gridHeight: 1
  ch2
    ch2-1
      gridHeight: 1
    ch2-2
      gridHeight: 1
    gridHeight: 2
  ch3
    gridHeight: 4
  gridHeight: 4
*/
export const calcGrid = (body, options) => {
  const gridHeights = Object.keys(body).map(key => {
    if (key === options.attrNodeName) return false;
    if (!body[key][options.gridHeightNodeName]) {
      if (typeof body[key] === 'object') {
        body[key] = calcGrid(body[key], options);
      }
      else return 1;
    }
    return body[key][options.gridHeightNodeName];
  });

  const currentGridHeight = sumArr(gridHeights);
  body[options.gridHeightNodeName] = currentGridHeight;
  return body;
};
