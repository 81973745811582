import { useState, useEffect } from 'react'

import '@styles/Settings.css'

const Settings = (props) => {

  useEffect(() => {

    const settingsBlockRef = document.getElementById('settings-block');

    document.addEventListener('keydown', (e) => {
      if (e.code === 'KeyF' && e.shiftKey && e.srcElement.localName !== 'input') {
        e.preventDefault();
        settingsBlockRef.classList.toggle('opened');
      }
    });

    settingsBlockRef.addEventListener('mouseenter', e => {
      settingsBlockRef.classList.add('opened');
    });

    settingsBlockRef.addEventListener('mouseleave', e => {
      settingsBlockRef.classList.remove('opened');
    });

  }, []);

  // converts empty string to false or does opposite
  const convertAttrNodeName = (value) => {
    switch (value) {
      case '': return false;
      case false: return '';
      default: return value;
    }
  }

  return <>
  <div id="settings-block">
    <div id="settings-hook">Settings</div>
    <label>
      Attribute node name <input type="text" value={convertAttrNodeName(props.options.attrNodeName)} onChange={e => props.setParserOptions({ ...props.options, attrNodeName: convertAttrNodeName(e.target.value) })} /><br/>
    </label><br/>
    <label>
      Attribute name prefix <input type="text" value={props.options.attributeNamePrefix} onChange={e => props.setParserOptions({ ...props.options, attributeNamePrefix: e.target.value })} /><br/>
    </label><br/>
    <label>
      Text node name <input type="text" value={props.options.textNodeName} onChange={e => props.setParserOptions({ ...props.options, textNodeName: e.target.value })} /><br/>
    </label><br/>
    <label>
      <input type="checkbox" checked={props.options.ignoreAttributes} onChange={e => props.setParserOptions({ ...props.options, ignoreAttributes: e.target.checked })} /> Ignore attributes
    </label><br/>
    <label>
      <input type="checkbox" checked={props.options.ignoreNameSpace} onChange={e => props.setParserOptions({ ...props.options, ignoreNameSpace: e.target.checked })} /> Ignore namespaces
    </label><br/>
    <label>
      <input type="checkbox" checked={props.options.allowBooleanAttributes} onChange={e => props.setParserOptions({ ...props.options, allowBooleanAttributes: e.target.checked })} /> Allow boolean attributes
    </label><br/>
    <label>
      <input type="checkbox" checked={props.options.parseNodeValue} onChange={e => props.setParserOptions({ ...props.options, parseNodeValue: e.target.checked })} /> Parse node value
    </label><br/>
    <label>
      <input type="checkbox" checked={props.options.parseAttributeValue} onChange={e => props.setParserOptions({ ...props.options, parseAttributeValue: e.target.checked })} /> Parse attribute value
    </label><br/>
    <label>
      <input type="checkbox" checked={props.options.separateIndexes} onChange={e => props.setParserOptions({ ...props.options, separateIndexes: e.target.checked })} /> Put array indexes in separate column
    </label><br/>
  </div>
  </>
}

export default Settings;
